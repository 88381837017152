import { ChefclubLocale, getIntl } from "~/utils/locales"

export function round(number: number, decimal: number): number {
  const factorOfTen = Math.pow(10, decimal)
  return Math.round(number * factorOfTen) / factorOfTen
}

export function numberFormatter(value: number, locale: ChefclubLocale): string {
  const intl = getIntl(locale)

  if (isNaN(value)) return "0"

  let displayedValue = value
  let unitSuffixMessageId = ""

  // Choose precision, unit
  if (value > 1000000) {
    displayedValue = value / 1000000
    unitSuffixMessageId = "text:x-abbr-million"
  } else if (value > 10000) {
    // 10000 above is not a typo, we want to display 12345 as 12.3K but 1234 as 1234, like Instagram
    displayedValue = value / 1000
    unitSuffixMessageId = "text:x-abbr-thousand"
  }

  // Format number
  const formattedCount = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 1,
  }).format(displayedValue)

  // Add unit suffix
  if (unitSuffixMessageId !== "") {
    return intl.formatMessage(
      { id: unitSuffixMessageId },
      { value: formattedCount }
    )
  } else {
    return String(formattedCount)
  }
}
