import React, { useEffect, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import Modal from "../Modal"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import CloseIcon from "~/components/Icons/CloseIcon"
import emptyStarIcon from "~/components/Icons/empty-star.svg"
import fullStarIcon from "~/components/Icons/full-star.svg"
import { Body, Button, Col, Heading, Loader, Row } from "~/components/ui"
import useShowEmailValidationModal, {
  emailValidationError,
} from "~/hooks/useShowEmailValidationModal"
import { APIRatingReasons } from "~/models/CommunityPost"
import {
  useGetRatingReasonsQuery,
  useGetUserRatingQuery,
  usePatchUserRatingMutation,
  usePostUserRatingMutation,
} from "~/state/api/community"
import { userSelector } from "~/state/modules/userInfo"
import { ChefclubLocale } from "~/utils/locales"

import css from "./RatingsModal.module.scss"

type RatingsModalProps = {
  image?: string
  title?: string
  uuid: string
  open: boolean
  onClose: (open: false) => void
}
type ratingReason = {
  available_for_stars: number[]
  uuid: string
  selected?: boolean
  slug: string
  translation: { name: string }
}
interface RatingState {
  isRated: boolean
  ratingScore: null | number
  isHovered: null | number
  ratingReasons: ratingReason[]
}

const RatingsModal = ({
  image,
  title,
  uuid,
  open,
  onClose,
}: RatingsModalProps) => {
  const intl = useIntl()
  const lang = intl.locale
  const currentUser = useSelector(userSelector)
  const numberOfStars = 5

  const initialState: RatingState = {
    isRated: false,
    ratingScore: null,
    isHovered: null,
    ratingReasons: [],
  }

  const [rating, setRating] = useState(initialState)

  const { isRated, ratingScore, isHovered, ratingReasons } = rating

  // Get rating reasons by language
  const {
    data: apiRatingReasons,
    isLoading,
    isSuccess,
  } = useGetRatingReasonsQuery({
    lang: lang as ChefclubLocale,
  })

  // Get user's rating if recipe already reviewed, skip if offline user (the modal shouldn't render otherwise anyway)
  const { data: fetchedPreviousRating, isSuccess: fetchedRatingSuccess } =
    useGetUserRatingQuery(
      {
        recipe_uuid: uuid,
        user: currentUser?.id,
      },
      { skip: !currentUser }
    )

  // POST/PATCH user's rating
  const [
    postRating,
    { isSuccess: ratingPosted, isLoading: ratingIsPosting, error: ratingError },
  ] = usePostUserRatingMutation()

  const [
    patchRating,
    {
      isSuccess: ratingPatched,
      isLoading: ratingIsPatching,
      error: patchingError,
    },
  ] = usePatchUserRatingMutation()

  const previousRating =
    fetchedRatingSuccess &&
    fetchedPreviousRating &&
    fetchedPreviousRating?.find(
      (rating: { user: string | undefined }) => rating?.user === currentUser?.id //TODO: Remove 3rd condition once the filtering is properly done on the API
    )

  useEffect(() => {
    if (isSuccess) {
      // Reasons filtered out by matching stars amount + added extra "selected" field
      const reasonsForScore = (apiRatingReasons as any)
        .filter((reason: { available_for_stars: (number | null)[] }) =>
          reason.available_for_stars.includes(ratingScore)
        )
        .map((reason: APIRatingReasons) => ({
          ...reason,
          selected: previousRating
            ? previousRating.reasons.some(
                (rating: { uuid: string }) => rating.uuid === reason.uuid
              )
            : false,
        }))

      setRating({
        ...rating,
        ratingReasons: reasonsForScore,
      })
    }
  }, [ratingScore])

  // Update state with retrieved data if any
  useEffect(() => {
    getPreviousRating()
  }, [fetchedRatingSuccess])

  const unRate = () => setRating(initialState)

  // Retrieves user's rating if it exists
  const getPreviousRating = () => {
    if (!fetchedRatingSuccess) return

    if (previousRating) {
      const score = previousRating.rating_score

      setRating({
        ...rating,
        isRated: !!fetchedPreviousRating.length,
        isHovered: score,
        ratingScore: score,
      })
    }
  }

  const giveRating = (idx: number): void =>
    setRating({ ...rating, ratingScore: idx, isRated: true })

  const closeModal = (): void => {
    onClose && onClose(false)
    unRate()
  }

  // Gets triggered whenever an API error ensues from sending/editing a rating
  useShowEmailValidationModal(ratingError as emailValidationError)
  useShowEmailValidationModal(patchingError as emailValidationError)

  // Toggles "selected" property of reason when clicked
  const toggleReason = (idx: number): void =>
    setRating({
      ...rating,
      ratingReasons: ratingReasons.map((reason: ratingReason, i: number) => ({
        ...reason,
        selected: i === idx ? !reason.selected : reason.selected,
      })),
    })

  // API's PUT or PATCH request depending on previous current user's rating
  const confirmRating = () => {
    const hasAlreadyRated =
      fetchedPreviousRating &&
      fetchedPreviousRating.some(rating => rating.user === currentUser?.id) // TODO: Replace with fetchedPreviousRating.length once the filtering is properly done on the API

    const ratingIds: { uuid: string }[] = []

    // Format request's "uuid" field for API call as [{uuid: #}, {uuid: #}, {uuid: #}...etc]
    ratingReasons
      .map((reason: ratingReason) => reason.uuid)
      .filter((_: any, idx: number) => ratingReasons[idx].selected)
      .map((uuid: string) => ratingIds.push({ uuid: uuid }))

    // TODO: update mandatory API comment field when users would be able to send one from UI
    const requestBody = {
      recipe_uuid: uuid,
      rating_score: ratingScore,
      reasons: ratingIds,
      comment: "Rating comment",
    }

    !!hasAlreadyRated ? patchRating(requestBody) : postRating(requestBody)
  }

  useEffect(() => {
    const ratingSent = ratingPosted || ratingPatched

    // Reload page if successful request
    ratingSent && location.reload()
  }, [ratingPosted, ratingPatched])

  return (
    <Modal open={open} onClose={closeModal} unmountOnExit mode="fixed">
      {modalRef => (
        <div
          className={cn(css.root, { [css.isRated]: isRated })}
          ref={modalRef}
        >
          <div className={css.closeButton} onClick={closeModal}>
            <CloseIcon width="26" height="26" />
          </div>

          <Row className={css.rowModal}>
            <Col
              width={12}
              className={cn(css.imageContainer, css.tilt)}
              style={{ backgroundImage: `url(${image})` }}
            />

            <Col width={12} className={css.content}>
              <Heading
                variant="modal"
                color="original"
                className={css.recipeTitle}
              >
                {title}
              </Heading>
              <div className={cn(css.starsContainer, { [css.shift]: isRated })}>
                {Array.from({ length: numberOfStars }).map((_, idx) => (
                  <ColorableSVG
                    key={idx++}
                    className={css.star}
                    href={
                      (isRated && ratingScore && ratingScore >= idx) ||
                      (isHovered && isHovered >= idx)
                        ? fullStarIcon
                        : emptyStarIcon
                    }
                    color="var(--cocktails-color)"
                    width={32}
                    height={31}
                    onMouseLeave={() =>
                      setRating({ ...rating, isHovered: null })
                    }
                    onMouseEnter={() =>
                      setRating({ ...rating, isHovered: idx })
                    }
                    onClick={
                      ratingScore === idx
                        ? () => unRate()
                        : () => giveRating(idx)
                    }
                  />
                ))}
              </div>
            </Col>
            <div
              className={cn(css.appearingSection, { [css.appear]: isRated })}
            >
              <div className={css.reasonsContainer}>
                {isLoading ? (
                  <Loader />
                ) : isRated && isSuccess && ratingReasons ? (
                  <>
                    {ratingReasons.map(
                      (reason: ratingReason, idx: number) =>
                        reason?.translation?.name?.length && (
                          <Body
                            key={idx}
                            variant="body2"
                            color={
                              ratingReasons[idx].selected ? "original" : "gray2"
                            }
                            semiBold
                            className={cn(css.ratingReason, {
                              [css.isActive]: ratingReasons[idx].selected,
                            })}
                            onClick={() => toggleReason(idx)}
                          >
                            {reason.translation.name}
                          </Body>
                        )
                    )}
                  </>
                ) : (
                  <Body variant="body2" semiBold>
                    {intl.formatMessage({
                      id: "recipe/comments/text:error-create-comment" /* TODO: Make general API error message */,
                    })}
                  </Body>
                )}
              </div>
              {isRated && isSuccess && ratingReasons && (
                <Button
                  className={css.confirmationButton}
                  variant="primary"
                  fullWidth
                  color="original"
                  onClick={confirmRating}
                  disabled={ratingIsPosting || ratingIsPatching}
                >
                  {ratingIsPosting || ratingIsPatching ? (
                    <Loader />
                  ) : (
                    intl.formatMessage({
                      id: "recipe/ratings/action:confirm",
                    })
                  )}
                </Button>
              )}
            </div>
          </Row>
        </div>
      )}
    </Modal>
  )
}

export default RatingsModal
