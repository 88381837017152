import React from "react"

import { FormattedMessage } from "react-intl"

import Avatar from "~/components/Avatar/Avatar"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import chefclubLogoImage from "~/components/Layout/SearchLayout/assets/chefclubAuthorPicture.svg"
import Link from "~/components/Link/Link"
import { Body, Heading } from "~/components/ui/Typography/Typography"
import { getUserTypeFromId } from "~/models/UserType"
import RecipeRatings from "~/templates/Recipe/RecipeRatings/RecipeRatings"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./FullImageRecipeCard.module.scss"

const FullImageRecipeCard = ({ hit }: { hit: any }) => {
  const userType = getUserTypeFromId(hit.author?.user_types[0])

  const isCreator = userType?.id === "creator"

  const isChefclubstaff = userType?.id === "chefclub-staff"

  const hasUsername = hit.author?.username?.length

  const hasAvatar = hit.author?.image_url?.length

  return (
    <Link
      to={`${hit.absolute_url.replace("/Rezepte/", "/rezepte/")}`} // To modify : Currently used to work with German legacy links of Algolia
      absoluteLink
      className={css.FullImageRecipeCard}
    >
      <div className={css.imageContainer}>
        <img
          className={css.image}
          src={hit.web_cover_thumbnail}
          alt={hit.title}
        />
      </div>
      <RecipeRatings
        ratingsAverage={hit.ratings_average?.toFixed(1)}
        ratingsCount={hit.ratings_count}
        isOnListingPage
      />
      <div className={css.infos}>
        <div className={css.title}>
          <Heading variant="extraSmall" noMargin>
            {hit.title}
          </Heading>
        </div>
        <div className={css.userInfos}>
          {hasUsername && !isChefclubstaff ? (
            /* Author isn't Chefclub staff + has username */
            <>
              <Avatar
                user={{
                  username: hit.author?.username,
                  id: "ChefClub",
                  profilePicture: hit.author?.image_url,
                  userTypes: hit.author?.user_types,
                }}
                variant={{ mobile: "extraExtraSmall", desktop: "extraSmall" }}
                color={
                  isCreator ? "var(--creators-color)" : "var(--gray4-color)"
                }
              />
              <div className={css.username}>
                <Body
                  variant="body4"
                  semiBold
                  color="black"
                  title={hit.author.username}
                >
                  {hit.author.username}
                </Body>
                {userType?.messageId && (
                  <Body variant="body4" semiBold color="gray2">
                    <FormattedMessage id={userType.messageId} />
                  </Body>
                )}
              </div>
            </>
          ) : (
            /* Author is Chefclub staff */
            <>
              {!hasAvatar ? (
                /* Show default Chefclub svg if author has no avatar */
                <ColorableSVG
                  className={css.logoChefclub}
                  href={chefclubLogoImage}
                  mainColor={`var(--${
                    hit.vertical === "kids" ? "kids" : "original"
                  }-color)`}
                  width="28"
                  height="28"
                  title="Chefclub"
                />
              ) : (
                /* Show author's avatar otherwise */
                <Avatar
                  user={{
                    username: hit.author?.username,
                    id: "ChefClub",
                    profilePicture: hit.author?.image_url,
                    userTypes: hit.author?.user_types,
                  }}
                  variant={{ mobile: "extraExtraSmall", desktop: "extraSmall" }}
                  color={
                    isCreator ? "var(--creators-color)" : "var(--gray4-color)"
                  }
                />
              )}
              <div className={css.username}>
                <Body variant="body4" semiBold color="black" title="Team">
                  {/* Show "Team" if author is nameless */}
                  {!hasUsername ? "Team" : hit.author?.username}
                </Body>

                <Body variant="body4" semiBold color="gray2">
                  {/* Show "Chefclub" if author is typeless (Chefclub-staff without being a chef for example) */}
                  {!userType?.messageId ? (
                    /* Show Chefclub Kids if within Kids vertical */
                    `Chefclub ${hit.vertical === "kids" ? "Kids" : ""}`
                  ) : (
                    <FormattedMessage id={userType.messageId} />
                  )}
                </Body>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  )
}

export default FullImageRecipeCard
