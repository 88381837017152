import React, { CSSProperties, useContext, useState } from "react"

import cn from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import fullStarIcon from "~/components/Icons/full-star.svg"
import RatingsModal from "~/components/Modals/RatingsModal/RatingsModal"
import { Body, Button } from "~/components/ui"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { authSelector } from "~/state/modules/auth"
import { ChefclubLocale } from "~/utils/locales"
import { numberFormatter } from "~/utils/number"

import css from "./RecipeRatings.module.scss"

export interface RatingTypes {
  ratingsAverage?: number | null | string
  ratingsCount?: number
}

interface PropTypes extends RatingTypes {
  isOnListingPage?: boolean
  image?: string
  title?: string
  uuid?: string
}

export default function RecipeRatings({
  image,
  title,
  uuid,
  ratingsAverage = 0,
  ratingsCount = 0,
  isOnListingPage,
}: PropTypes) {
  const intl = useIntl()
  const locale = intl.locale as ChefclubLocale
  const isCompact = useIsOnCompact()
  const { isLoggedIn } = useSelector(authSelector)
  const { toggleModal } = useContext(ModalLoginContext)
  const [isRatingsModalOpen, setIsRatingsModalOpen] = useState<boolean>(false)

  // Prompts login if offline user tries to review recipe
  const handleLoginModal = () => {
    toggleModal &&
      toggleModal(
        intl.formatMessage({
          id: "onboard/text:login-intent-rate-recipe",
        })
      )
  }

  const handleRatingModal = () => {
    !isRatingsModalOpen && setIsRatingsModalOpen(true)
  }

  const marginStyles: CSSProperties = isOnListingPage
    ? {
        marginBottom: "-15px",
      }
    : { marginTop: "-16px" }

  const star = {
    width: isOnListingPage ? (isCompact ? 12 : 16) : 20,
    height: isOnListingPage ? (isCompact ? 11 : 15) : 20,
  }

  const formattedRatingsCount = numberFormatter(ratingsCount, locale)

  return (
    <>
      <div
        className={cn(css.ratingsSection, {
          [css.shift]: isOnListingPage,
        })}
        style={marginStyles}
      >
        <div className={css.leftContainer}>
          {!!ratingsAverage && (
            <div className={css.scoreContainer}>
              <ColorableSVG
                className={css.star}
                href={fullStarIcon}
                color="var(--cocktails-color)"
                width={star.width}
                height={star.height}
              />
              <Body variant={isOnListingPage ? "body3" : "body1"} semiBold>
                {ratingsAverage}
              </Body>
            </div>
          )}

          <Body
            variant={isOnListingPage ? "body4" : "body2"}
            semiBold={!isOnListingPage}
            color="gray2"
          >
            <FormattedMessage
              id="recipe/ratings/text:rating-count"
              values={{ count: formattedRatingsCount }}
            />
          </Body>
        </div>

        {!isOnListingPage && (
          <Button
            className={css.ctaButton}
            variant="secondary"
            color="black"
            onClick={isLoggedIn ? handleRatingModal : handleLoginModal}
          >
            {intl.formatMessage({
              id: "recipe/action:rate",
            })}
          </Button>
        )}
      </div>

      {isRatingsModalOpen && !isOnListingPage && uuid && (
        <RatingsModal
          open={isRatingsModalOpen}
          onClose={setIsRatingsModalOpen}
          image={image}
          title={title}
          uuid={uuid}
        />
      )}
    </>
  )
}
