import { useEffect } from "react"

export type emailValidationError = {
  data: { details_v2: { error_code: string }[] }
}

const ERROR_CODE = "validated_email_required"

// TODO: Get user's email-validation status from user context and conditionally send post request
// to prevent 400 error on console (to prevent possible lighthouse score drop)

// Opens up modal to prompt user to validate email when API sends back specific error code
// Then performs callback function if passed
export default function useShowEmailValidationModal(
  apiError: emailValidationError,
  callbackFunction?: () => void
) {
  useEffect(() => {
    const emailIsNotValidated = apiError?.data?.details_v2.some(
      error => error.error_code === ERROR_CODE
    )
    emailIsNotValidated && window.openEmailValidationModal()
  }, [apiError])

  callbackFunction && callbackFunction()
}
