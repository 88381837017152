import React from "react"

import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import FullImageRecipeCard from "~/components/FilteredRecipes/Recipes/Cards/FullImageRecipeCard"
import Link from "~/components/Link/Link"
import SEO from "~/components/Seo/Seo"
import {
  Col,
  Container,
  FormattedBody,
  FormattedHeading,
  Row,
} from "~/components/ui"
import useSiteMetadata from "~/hooks/useSiteMetadata"
import { capitalizeFirstLetter } from "~/utils/string"

export type PageContextIngredients = {
  ingredientValue: string
  hitsByCategory: string
  slug: string
  name: string
  nbHits: number
}

interface PropTypes extends TemplateDefaultPropTypes {
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextIngredients
}

function IngredientPage({ pageContext }: PropTypes) {
  const intl = useIntl()
  const { siteUrl } = useSiteMetadata()

  const ingredientSlug = pageContext.ingredientValue
  const ingredientName = capitalizeFirstLetter(pageContext.name)
  const hitsByCategory = JSON.parse(pageContext.hitsByCategory)

  if (Object.keys(hitsByCategory).length === 0) {
    return (
      <>
        <SEO
          title={ingredientName}
          meta={[{ name: "robots", content: "noindex,nofollow" }]}
        />
        <Container>
          <FormattedBody
            translationId="ingredient-sitemap/text:no-recipe-error"
            translationValues={{ ingredient: ingredientName }}
          />
          <Link to={"all-ingredients-sitemap"}>
            <FormattedBody translationId="ingredient-sitemap/text:return-to-ingredients-list" />
          </Link>
        </Container>
      </>
    )
  }

  const baseURL = `/${intl.locale}/${intl.formatMessage({
    id: "path:all-ingredients-sitemap",
  })}`

  const hitsByIds: { [key: string]: any } = {}
  Object.keys(hitsByCategory).forEach((categoryId: any) => {
    hitsByCategory[categoryId].hits.forEach((hit: any) => {
      hitsByIds[hit.id] = hit
    })
  })
  const jsonld = `{
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [${Object.keys(hitsByIds).map(
      (hitId, index) => `{
          "@type": "ListItem",
          "position": ${index + 1},
          "image": "${hitsByIds[hitId].web_cover_thumbnail}",
          "url": "${siteUrl + hitsByIds[hitId].absolute_url}",
          "name": "${hitsByIds[hitId].title}"
        }`
    )}
     ]
  }`

  return (
    <>
      <SEO
        title={intl.formatMessage(
          { id: "ingredient-sitemap/text:title" },
          {
            ingredient: ingredientName,
          }
        )}
        description={intl.formatMessage(
          { id: "ingredient-sitemap/text:description" },
          {
            count: pageContext.nbHits,
            ingredient: ingredientName,
          }
        )}
        image={
          hitsByCategory[Object.keys(hitsByCategory)[0]].hits[0]
            .web_cover_thumbnail
        }
      />
      <Helmet>
        <script type="application/ld+json">{jsonld}</script>
      </Helmet>
      <Breadcrumb
        pageTitle={ingredientName}
        parent={{
          link: baseURL,
          label: intl.formatMessage({
            id: "ingredient-sitemap/text:breadcrumb-base",
          }),
        }}
      />
      <Container>
        <FormattedHeading
          translationId="ingredient-sitemap/text:title"
          translationValues={{
            ingredient: ingredientName,
          }}
          variant="extraLarge"
          Tag="h1"
        />
        <FormattedBody
          translationId="ingredient-sitemap/text:description"
          translationValues={{
            count: pageContext.nbHits,
            ingredient: ingredientName,
          }}
        />
        {Object.keys(hitsByCategory).map((categoryId: any) => (
          <div key={categoryId}>
            {hitsByCategory[categoryId].hits.length < 5 ? (
              <FormattedHeading
                translationId="ingredient-sitemap/text:ingredient-in-category"
                translationValues={{
                  ingredient: ingredientName,
                  category: hitsByCategory[categoryId].category.name,
                }}
                variant="large"
              />
            ) : (
              <Link
                to={`c/categories`}
                params={{
                  category: hitsByCategory[categoryId].category.slug,
                  ingredient: ingredientSlug,
                }}
                style={{ textDecoration: "none" }}
              >
                <FormattedHeading
                  translationId="ingredient-sitemap/text:ingredient-in-category"
                  translationValues={{
                    ingredient: ingredientName,
                    category: hitsByCategory[categoryId].category.name,
                  }}
                  variant="large"
                />
              </Link>
            )}
            <Row>
              {hitsByCategory[categoryId].hits.map((hit: any) => (
                <Col width={[6, 4, 3]} key={hit.objectID}>
                  <FullImageRecipeCard hit={hit} key={hit.id} />
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Container>
    </>
  )
}
export default withTemplate(IngredientPage)
